import React, { useState } from 'react';

const Leaderboard = ({ leaderboard, publicKey }) => {
  const [isLeaderboardVisible, setIsLeaderboardVisible] = useState(false);

  const handleToggleLeaderboard = () => {
    setIsLeaderboardVisible(!isLeaderboardVisible);
  };

  const handleCloseLeaderboard = () => {
    setIsLeaderboardVisible(false);
  };

  return (
    <>
      <button className="leaderboard-button" onClick={handleToggleLeaderboard}>
        {isLeaderboardVisible ? 'Hide Leaderboard' : 'View Leaderboard'}
      </button>
      {isLeaderboardVisible && (
        <div className="leaderboard">
          <h2>Top 10 Leaderboard</h2>
          {!publicKey && <p>Login with Phantom to track your score here!</p>}
          <button className="close-button" onClick={handleCloseLeaderboard}>
            ×
          </button>
          <ol>
            {leaderboard.map((entry, index) => (
              <li key={index}>
                {entry.walletaddress.substring(0, 3)}...{entry.walletaddress.substring(entry.walletaddress.length - 3)}: {Number(entry.score).toFixed(2)}
              </li>
            ))}
          </ol>
        </div>
      )}
    </>
  );
};

export default Leaderboard;