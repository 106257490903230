import React from 'react';
import logo from '../images/logo.png';

function Popup({ closePopup }) {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2><img className='logo' src={logo} alt="$AUTISM" />FISH SPINNER</h2>
        <ol>
          <li>Scroll or swipe to spin the fish.</li>
          <li>Login with Phantom to track your spins on the leaderboard.</li>
          <li>Submit your score when you're done spinning.</li>
          <li>HOLD <a href="https://raydium.io/swap/?outputMint=BkVeSP2GsXV3AYoRJBSZTpFE8sXmcuGnRQcFgoWspump" target="_blank" title="GET $AUTISM ON RAYDIUM" rel="noreferrer">$AUTISM</a> FOR A <br/><span className='bonus'>BONUS MULTIPLIER</span>!!!</li>
        </ol>
        <button onClick={closePopup}>START</button>
      </div>
    </div>
  );
}

export default Popup;