import React from 'react';
import icon from '../images/info.svg';

function InfoIcon({ openPopup }) {
  return (
    <button className="info-button" onClick={openPopup} title="INFO">
      <img src={icon} alt="info icon" />
    </button>
  );
}

export default InfoIcon;