import React from 'react';

function Popup({
  closePopup,
  scrollBackgroundEnabled,
  setScrollBackgroundEnabled,
  pulsingNumbersEnabled,
  setPulsingNumbersEnabled,
}) {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>SETTINGS</h2>
        <p>Scrolling background: <input type="checkbox" checked={scrollBackgroundEnabled} onChange={(e) => setScrollBackgroundEnabled(e.target.checked)} /></p>
        <p>Pulsing numbers: <input type="checkbox" checked={pulsingNumbersEnabled} onChange={(e) => setPulsingNumbersEnabled(e.target.checked)} /></p>
        <button onClick={closePopup}>CLOSE</button>
      </div>
    </div>
  );
}

export default Popup;