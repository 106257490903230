import React from 'react';
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

require('@solana/wallet-adapter-react-ui/styles.css');

const PhantomButton = React.memo(() => {
  return (
    <>
      <WalletMultiButton />
    </>
  );
});

export default PhantomButton;
