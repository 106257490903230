import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useWallet, WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { PublicKey, Connection, GetProgramAccountsFilter } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

import { Canvas } from '@react-three/fiber';
import ScrollingBackground from './components/ScrollingBackground';
import Model from './components/Model';
import Counter from './components/Counter';
import Heading from './components/Heading';
import Leaderboard from './components/Leaderboard';
import InfoPopup from './components/InfoPopup';
import SettingsPopup from './components/SettingsPopup';
import InfoIcon from './components/InfoIcon';
import SettingsIcon from './components/SettingsIcon';
import './App.css';

import { PhantomButton, AutoConnectProvider } from './components';

import useWebSocket, { ReadyState } from 'react-use-websocket';

const apiUrl = process.env.REACT_APP_API_URL;
const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;

const TOKEN_ADDRESS = 'BkVeSP2GsXV3AYoRJBSZTpFE8sXmcuGnRQcFgoWspump';

const StatelessApp = () => {
  const { wallet, publicKey, signMessage } = useWallet();
  const [tokenBalance, setTokenBalance] = useState<null | number>(null);

  const [rotationCounter, setRotationCounter] = useState(0);
  const [cumulativeRotation, setCumulativeRotation] = useState(0);
  const scrollSpeed = useRef(0); // Store the scrolling speed
  const [scrollBackgroundEnabled, setScrollBackgroundEnabled] = useState(true);
  const [pulsingNumbersEnabled, setPulsingNumbersEnabled] = useState(true);

  const [leaderboard, setLeaderboard] = useState([]);
  const [isScoreLoaded, setIsScoreLoaded] = useState(false); // Track if the score has been loaded
  const [multiplier, setMultiplier] = useState(0);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [scoreIsUpdating, setScoreIsUpdating] = useState(false);
  const [lastScoreSubmitted, setLastScoreSubmitted] = useState(0);

  const { sendMessage, lastMessage, readyState } = useWebSocket(websocketUrl, {
    // onOpen: () => console.log('WebSocket connection opened'),
    // onClose: () => console.log('WebSocket connection closed'),
    shouldReconnect: () => true,
  });

  const attemptSubmitScore = () => {
    if (!publicKey || !wallet || readyState !== ReadyState.OPEN || !isScoreLoaded) return;

    setScoreIsUpdating(true);

    // Send an attempt to submit score message to the server
    sendMessage(
      JSON.stringify({
        type: 'attempt-submit-score',
        data: {
          score: rotationCounter,
          walletAddress: publicKey.toBase58(),
        }
      })
    );
  };

  const handleSignMessage = useCallback(
    async (messageToSign, nonce) => {
      if (!publicKey || !signMessage) return;

      try {
        const encodedMessage = new TextEncoder().encode(messageToSign);
        const signedMessage = await signMessage(encodedMessage);

        // Send the signed message back to the server
        sendMessage(
          JSON.stringify({
            type: 'submit-signed-score',
            signedMessage: Array.from(signedMessage),
            messageToSign,
            walletAddress: publicKey.toBase58(),
            score: rotationCounter,
            nonce
          })
        );
      } catch (error) {
        console.error('Error signing message:', error);
        setScoreIsUpdating(false);
      }
    },
    [publicKey, signMessage, sendMessage, rotationCounter]
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      if (data.type === 'leaderboard') {
        setLeaderboard(data.data);
      }

      if (data.type === 'sign-message') {
        handleSignMessage(data.message, data.nonce);
      }

      if (data.type === 'score-update-success') {
        setScoreIsUpdating(false);
        setLastScoreSubmitted(data.score);
        // console.log(data);
      }
    }
  }, [lastMessage, handleSignMessage]);

  useEffect(() => {
    if (!wallet) {
      setRotationCounter(0);
      setIsScoreLoaded(false);
      setMultiplier(0);
      setTokenBalance(null);
    }
  }, [wallet]);

  // Fetch the user's score when they log in
  useEffect(() => {
    const fetchUserScore = async () => {
      if (publicKey) {
        // console.log('Fetching score for wallet:', publicKey.toBase58());
        const response = await fetch(`${apiUrl}/leaderboard/${publicKey.toBase58()}`);
        const data = await response.json();
        // console.log('Fetched score:', data.score);
        setRotationCounter(data.score);
        setLastScoreSubmitted(data.score);
        setIsScoreLoaded(true); // Mark the score as loaded
      }
    };

    fetchUserScore();
  }, [publicKey]);

  useEffect(() => {
    if (!tokenBalance) return;
    // Ensure the token balance doesn't exceed the maximum token cap
    const cap = 1000000000 * 0.15;
    const cappedBalance = Math.min(tokenBalance, cap);

    // Calculate the multiplier based on the capped token balance
    const multiplier = 1 + ((cappedBalance / cap) * (2 - 1));
    setMultiplier(multiplier);
  }, [tokenBalance]);

  // Get $autism balance
  const fetchTokenBalance = useCallback(
    async () => {
      if (!publicKey) return;

      setIsRefreshing(true);

      try {
        const connection = new Connection('https://crimson-long-dawn.solana-mainnet.quiknode.pro/e3b3aabd91ec06ea0695d11ba1af85cf374c6ce0');
        const filters:GetProgramAccountsFilter[] = [
            {
              dataSize: 165,    //size of account (bytes)
            },
            {
              memcmp: {
                offset: 32,     //location of our query in the account (bytes)
                bytes: publicKey.toBase58(),  //our search criteria, a base58 encoded string
              }            
            },
            {
              memcmp: {
                offset: 0, //number of bytes
                bytes: new PublicKey(TOKEN_ADDRESS).toBase58(), //base58 encoded string
              },
            }
        ];
        const accounts = await connection.getParsedProgramAccounts(
            TOKEN_PROGRAM_ID,   //SPL Token Program, new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
            {filters: filters}
        );
        if( accounts.length > 0 ) {
          const balance = accounts[0].account.data.parsed.info.tokenAmount.uiAmount;
          setTokenBalance(balance);
          setIsRefreshing(false);
        } else {
          setTokenBalance(0);
          setIsRefreshing(false);
        }
      } catch (error) {
        console.error('Error fetching token balance:', error);
        setTokenBalance(0);
        setIsRefreshing(false);
      }
    },
    [publicKey]
  );

  useEffect(() => {
    if (!publicKey) return;

    fetchTokenBalance();
  }, [publicKey, fetchTokenBalance]);

  useEffect(() => {
    // Check if the user has visited the site before
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      // If not, show the popup and store the fact that the user has now visited
      setShowInfoPopup(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const closeInfoPopup = () => {
    setShowInfoPopup(false);
  };

  const openInfoPopup = () => {
    setShowInfoPopup(true);
  };

  const [showSettingsPopup, setShowSettingsPopup] = useState(false);

  const openSettingsPopup = () => {
    setShowSettingsPopup(true);
  };

  const closeSettingsPopup = () => {
    setShowSettingsPopup(false);
  };

  return (
    <>
      <Canvas>
        <ScrollingBackground scrollSpeed={scrollSpeed} enabled={scrollBackgroundEnabled} />
        <ambientLight />
        <Model
          setRotationCounter={setRotationCounter}
          setCumulativeRotation={setCumulativeRotation}
          cumulativeRotation={cumulativeRotation}
          rotationCounter={rotationCounter}
          scrollSpeed={scrollSpeed}
          multiplier={multiplier}
        />
      </Canvas>
      <InfoIcon openPopup={openInfoPopup} />
      {showInfoPopup && <InfoPopup closePopup={closeInfoPopup} />}
      <SettingsIcon openPopup={openSettingsPopup} />
      {showSettingsPopup && (
        <SettingsPopup
          closePopup={closeSettingsPopup}
          scrollBackgroundEnabled={scrollBackgroundEnabled}
          setScrollBackgroundEnabled={setScrollBackgroundEnabled}
          pulsingNumbersEnabled={pulsingNumbersEnabled}
          setPulsingNumbersEnabled={setPulsingNumbersEnabled}
        />
      )}
      <Counter
        count={rotationCounter}
        tokenBalance={tokenBalance}
        multiplier={multiplier}
        pulsingNumbersEnabled={pulsingNumbersEnabled}
        isRefreshing={isRefreshing}
        refresh={fetchTokenBalance}
        publicKey={publicKey}
      />
      <Heading />
      <PhantomButton publicKey={publicKey} />
      {
        wallet &&
        publicKey &&
        isScoreLoaded &&
        rotationCounter > 0 &&
        !scoreIsUpdating &&
        lastScoreSubmitted !== rotationCounter && 
        <button className="submit-score" onClick={attemptSubmitScore}>SUBMIT SCORE</button>
      }
      <Leaderboard leaderboard={leaderboard} publicKey={publicKey} />
    </>
  );
};

const App = () => {
  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = `https://api.mainnet-beta.solana.com`;

  const wallets = useMemo(
    () => [], // confirmed also with `() => []` for wallet-standard only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  return (
    <AutoConnectProvider>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <StatelessApp />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </AutoConnectProvider>
  );
};

export default App;
