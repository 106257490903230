
import React from 'react';

function Heading() {
  return (
    <h1>
      <span className="heading">$AUTISM ON SOLANA</span> <br />
      <span className="ca">CA: BkVeSP2GsXV3AYoRJBSZTpFE8sXmcuGnRQcFgoWspump</span>
    </h1>
  );
}

export default Heading;
