import React from 'react';
import icon from '../images/refresh.svg';

function Counter({ count, tokenBalance, multiplier, publicKey, pulsingNumbersEnabled, isRefreshing, refresh }) {
  return (
    <div id="counter" className={!pulsingNumbersEnabled ? 'no-pulsing' : ''}>
      Spins: <span id="count">{Math.round(count)}</span>
      <br/>
      {tokenBalance > 0 && publicKey && (
        <span className="balance">
          $AUTISM: {tokenBalance.toLocaleString()}
          <button className="refresh" title="Refresh $autism balance" onClick={refresh}>
            <img className={isRefreshing ? 'rotate' : ''} src={icon} alt="refresh icon" />
          </button>
        </span>
      )}
      <br/>
      {multiplier > 1 && publicKey && <span className="multiplier">BONUS: x{multiplier.toFixed(5)}</span>}
    </div>
  );
}

export default Counter;
