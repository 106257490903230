
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Plane, GradientTexture } from '@react-three/drei';
import * as THREE from 'three';

function ScrollingBackground({ scrollSpeed, enabled }) {
  const materialRef = useRef();
  const offsetY = useRef(0); // Keep track of the cumulative offset

  useFrame(() => {
    if (enabled && materialRef.current && scrollSpeed.current !== 0) {
      // Increment the offset by the current scroll speed
      offsetY.current += scrollSpeed.current;
      materialRef.current.map.offset.y = offsetY.current;
    }
  });

  return (
    <Plane args={[1000, 1000]} position={[0, 75, -150]}>
      <meshBasicMaterial ref={materialRef} attach="material">
        <GradientTexture
          stops={[0, 0.31, 0.49, 0.62, 0.73, 0.81, 0.88, 0.94, 1]}
          colors={[
            '#000066',
            '#8a0067',
            '#db0054',
            '#f4331b',
            '#ffbf00',
            '#f5ed00',
            '#d4f000',
            '#b8f500',
            '#000066',
          ]}
          size={1024}
          wrapT={THREE.RepeatWrapping} // Ensure the texture repeats vertically
        />
      </meshBasicMaterial>
    </Plane>
  );
}

export default ScrollingBackground;
