import React from 'react';
import icon from '../images/settings.svg';

function SettingsIcon({ openPopup }) {
  return (
    <button className="settings-button" onClick={openPopup} title="SETTINGS">
      <img src={icon} alt="settings icon" />
    </button>
  );
}

export default SettingsIcon;