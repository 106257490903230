import React, { useRef, useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useFBX } from '@react-three/drei';

function Model({ setRotationCounter, setCumulativeRotation, cumulativeRotation, rotationCounter, scrollSpeed, multiplier }) {
  const model = useRef();
  const previousRotation = useRef(0);
  const [velocity, setVelocity] = useState(0);
  const [isTouching, setIsTouching] = useState(false);
  const touchStartY = useRef(0);

  const fbx = useFBX('./models/model.fbx');

  useFrame(() => {
    if (model.current) {
      // Calculate rotation changes
      const deltaRotation = model.current.rotation.y - previousRotation.current;
      const newCumulativeRotation = cumulativeRotation + Math.abs(deltaRotation);

      // Update rotation counter if full rotation is achieved
      if (newCumulativeRotation >= Math.PI * 2) {
        setRotationCounter(rotationCounter + (1 * (multiplier ? multiplier : 1)));
        setCumulativeRotation(newCumulativeRotation - Math.PI * 2);
      } else {
        setCumulativeRotation(newCumulativeRotation);
      }

      // Apply velocity if the model is still spinning
      if (Math.abs(velocity) > 0.001) {
        model.current.rotation.y += velocity;
        setVelocity(velocity * 0.98); // Deceleration

        // Continue updating the rotation counter even after touch ends
        const continuedDeltaRotation = model.current.rotation.y - previousRotation.current;
        const continuedNewCumulativeRotation = cumulativeRotation + Math.abs(continuedDeltaRotation);
        
        if (continuedNewCumulativeRotation >= Math.PI * 2) {
          setRotationCounter(rotationCounter + (1 * (multiplier ? multiplier : 1)));
          setCumulativeRotation(continuedNewCumulativeRotation - Math.PI * 2);
        } else {
          setCumulativeRotation(continuedNewCumulativeRotation);
        }
      }

      previousRotation.current = model.current.rotation.y;
    }
  });

  useEffect(() => {
    let wheelTimeout;
    let touchTimeout;
    let lastTouchY = null;
    let lastTouchTime = null;
    let lastScrollTime = null;

    const handleTouchStart = (event) => {
      if (!event.isTrusted) return;
      const now = Date.now();
      if (now - lastTouchTime < 50) { // Limit to one touch event every 50ms (adjust as needed)
        // console.warn('Touch event ignored: too fast');
        return;
      }
      lastTouchTime = now;

      if (event.touches.length === 1) {
        setIsTouching(true);
        touchStartY.current = event.touches[0].clientY;

        // Clear any existing timeout
        if (touchTimeout) clearTimeout(touchTimeout);
      }
    };

    const handleTouchMove = (event) => {
      if (!event.isTrusted) return;
      const touch = event.touches[0];
      const now = Date.now();
      if (lastTouchY !== null && lastTouchTime !== null) {
        const deltaY = Math.abs(touch.clientY - lastTouchY);
        const deltaTime = now - lastTouchTime;
        const speed = deltaY / deltaTime;
        console.log(speed);

        // Check if the swipe is too fast or slow (indicating automation)
        if (speed > 30) {
          // console.warn('Unrealistic touch event detected');
          return;
        }
      }
      lastTouchY = touch.clientY;
      lastTouchTime = now;

      if (event.touches.length === 1) {
        const delta = (touchStartY.current - touch.clientY) * 0.01;
        model.current.rotation.y += delta;
        setVelocity(delta);
        touchStartY.current = touch.clientY;
        
        scrollSpeed.current = delta * 0.1; // Link touch events to background speed
      }
    };

    const handleTouchEnd = (event) => {
      if (!event.isTrusted) return;
      
      setIsTouching(false);

      // Apply inertia to continue the background scroll smoothly after touch ends
      touchTimeout = setTimeout(() => {
        if (Math.abs(scrollSpeed.current) > 0.001) {
          scrollSpeed.current *= 0.95; // Gradual deceleration
        } else {
          scrollSpeed.current = 0; // Stop when speed is negligible
        }
      }, 100);
    };

    const handleWheel = (event) => {
      if (!event.isTrusted) return;

      const now = Date.now();
      if (lastScrollTime !== null) {
        const deltaY = event.deltaY;
        const deltaTime = now - lastScrollTime;
        const speed = deltaY / deltaTime;

        // Check if the swipe is too fast or slow (indicating automation)
        if (speed > 30) {
          // console.warn('Unrealistic touch event detected');
          return;
        }
      }
      
      const delta = event.deltaY * 0.005;
      model.current.rotation.y += delta;
      scrollSpeed.current = delta * 0.02; // Link scroll events to background speed

      lastScrollTime = now;

      // Clear any existing timeout
      if (wheelTimeout) clearTimeout(wheelTimeout);

      // Stop the background movement when scrolling stops
      wheelTimeout = setTimeout(() => {
        scrollSpeed.current = 0;
      }, 100); // Adjust this delay to fine-tune when the background should stop
    };

    window.addEventListener('wheel', handleWheel);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
      if (wheelTimeout) clearTimeout(wheelTimeout);
      if (touchTimeout) clearTimeout(touchTimeout);
    };
  }, [scrollSpeed]);

  return (
    <>
      <primitive
        ref={model}
        object={fbx}
        scale={0.004}
        rotation={[0, Math.PI / 2, 0]}
      />
    </>
  );
}

export default Model;
